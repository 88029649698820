import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"elevation":"0","color":"primary","loading":_vm.loading,"disabled":_vm.disabled || _vm.loading}},on),[_c('span',[_vm._v(_vm._s(_vm.$t("company.adde")))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.addConfirm($event)}}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("company.adde")))]),_c(VCardText,[_vm._l((_vm.users),function(item,i){return _c('div',{key:i,staticClass:"d-flex align-center"},[_c(VTextField,{staticStyle:{"flex":"1"},attrs:{"type":"email","hide-details":"","error":item.invalid,"label":_vm.$t('company.eemail')},model:{value:(item.UserEmail),callback:function ($$v) {_vm.$set(item, "UserEmail", $$v)},expression:"item.UserEmail"}}),_c(VSwitch,{staticClass:"ml-4",attrs:{"label":_vm.$t('company.makeman')},model:{value:(item.isManager),callback:function ($$v) {_vm.$set(item, "isManager", $$v)},expression:"item.isManager"}}),_c(VBtn,{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1)],1)}),_c('div',{staticClass:"d-flex justify-center mt-4"},[_c(VBtn,{attrs:{"elevation":"0","small":""},on:{"click":_vm.append}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t("company.esingle")))])],1)],1)],2),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticStyle:{"color":"black"},attrs:{"color":"accent","elevation":"0"},on:{"click":_vm.addCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.cancel")))])]),_c(VBtn,{attrs:{"type":"submit","elevation":"0","color":"primary","loading":_vm.addLoading,"disabled":_vm.addLoading}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.submit")))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }